<template>
    <div v-if="state.loading.availableVendors" class="flex justify-content-center">
        <div class="flex justify-content-center align-items-center" :style="{ width: '1261px', height: '664px' }">
            <ProgressSpinner />
        </div>
    </div>
    <div v-else class="bg-white p-6">
        <h3 class="text-center ">{{ title }}</h3>
        <form class="p-fluid formgrid grid gap-5 form flex flex-column align-items-center w-full">

            <h4 class="mt-3">Configurações Gerais</h4>

            <div class="grid w-full ">

                <div class="field col-4">
                    <label>Fornecedor: </label>
                    <MultiSelect v-model="state.selectedVendors" display="chip" :options="state.availableVendors"
                        optionLabel="name" :maxSelectedLabels="3" />
                </div>

                <div class="field col-4">
                    <label>Limite de Saque Diário (R$): </label>
                    <InputNumber v-model="state.configuration.daily_withdraw_amount" inputId="currency-us"
                        mode="currency" currency="BRL" />
                </div>

                <div class="field col-4">
                    <label>Valor Mínimo de Saque por Transação: </label>
                    <InputNumber v-model="state.configuration.min_withdraw_value" inputId="currency-us" mode="currency"
                        currency="BRL" />
                </div>

            </div>

            <div class="grid  w-full">

                <div class="field col-4">
                    <label>Valor Máximo de Saque por Transação: </label>
                    <InputNumber v-model="state.configuration.max_withdraw_value" inputId="currency-us" mode="currency"
                        currency="BRL" />
                </div>


                <div class="field col-4">
                    <label>Limite de Depósito Diário (R$): </label>
                    <InputNumber v-model="state.configuration.daily_deposit_amount" inputId="currency-us"
                        mode="currency" currency="BRL" />
                </div>

                <div class="field col-4">
                    <label>Valor Mínimo de Depósito por Transação: </label>
                    <InputNumber v-model="state.configuration.min_deposit_value" inputId="currency-us" mode="currency"
                        currency="BRL" />
                </div>

            </div>


            <div class="grid w-full">

                <div class="field col-4">
                    <label>Valor Máximo de Depósito por Transação: </label>
                    <InputNumber v-model="state.configuration.max_deposit_value" inputId="currency-us" mode="currency"
                        currency="BRL" />
                </div>

                <div class="field col-4">
                    <label>Quantidade Depósitos por Dia: </label>
                    <InputNumber v-model="state.configuration.daily_number_deposit" />
                </div>

                <div class="field col-4">
                    <label>Quantidade Saques por Dia: </label>
                    <InputNumber v-model="state.configuration.daily_number_withdraw" />
                </div>

            </div>

            <div class="grid w-full">

                <div class="field col-4">
                    <label>Status de Depósito: </label>
                    <Dropdown v-model="state.deposit_enabled" :options="state.enabledOptions" optionLabel="text" />
                </div>

                <div class="field col-4">
                    <label>Status de Saque: </label>
                    <Dropdown v-model="state.withdraw_enabled" :options="state.enabledOptions" optionLabel="text" />
                </div>

            </div>

            <h4 class="mt-3">Configurações Por CPF</h4>

            <div class="grid w-full">

                <div class="field col-4">
                    <label>Limite de Depósito Diário por CPF (R$): </label>
                    <InputNumber v-model="state.configuration.daily_deposit_amount_per_cpf" inputId="currency-us"
                        mode="currency" currency="BRL" />
                </div>

                <div class="field col-4">
                    <label>Limite de Saque Diário por CPF (R$): </label>
                    <InputNumber v-model="state.configuration.daily_withdraw_amount_per_cpf" inputId="currency-us"
                        mode="currency" currency="BRL" />
                </div>

                <div class="field col-4">
                    <label>Quantidade de Depósitos Diário por CPF: </label>
                    <InputNumber v-model="state.configuration.daily_number_deposit_per_cpf" />
                </div>

            </div>

            <div class="grid w-full">

                <div class="field col-4">
                    <label>Quantidade de Saques Diário por CPF: </label>
                    <InputNumber v-model="state.configuration.daily_number_withdraw_per_cpf" />
                </div>

            </div>

            <div class="grid w-full flex justify-content-center">

                <div class="field col-4">
                    <Button class="" :loading="state.tryingToCreateConfiguration || state.tryingToUpdateConfiguration"
                        :label="buttonLabel" icon="pi pi-check" iconPos="right" @click="checkInvalidValuesAndCallApi" />
                </div>

                <!-- <div class="field col-4">
                    <label>Status de Saque Manual: </label>
                    <Dropdown v-model="state.manual_withdraw" :options="state.enabledOptions" optionLabel="text" />
                </div> -->

            </div>

        </form>
    </div>
</template>

<script>

import InputNumber from 'primevue/inputnumber'
import MultiSelect from 'primevue/multiselect'
import Dropdown from 'primevue/dropdown'
import Button from 'primevue/button'
import ProgressSpinner from 'primevue/progressspinner'

import { reactive, computed } from 'vue'
import useNotificationToast from '../../../composables/useNotificationToast'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import services from '../../../services'

export default {

    inheritAttrs: false,

    components: { InputNumber, Dropdown, MultiSelect, Button, ProgressSpinner },

    props: {
        title: {
            type: String,
            default: 'Criando Nova Configuração'
        },
        client: {
            type: Object
        },
        configurationToUpate: {
            type: Object
        },
        isUpdanting: {
            type: Boolean,
            deafult: false
        },
        isCloning: {
            type: Boolean,
            deafult: false
        },
    },

    emits: ['realoadConfigurations', 'closeUpdateModal'],

    setup(props, context) {

        const state = reactive({
            availableVendors: null,
            selectedVendors: [],
            configuration: {
                min_withdraw_value: null,
                max_withdraw_value: null,
                min_deposit_value: null,
                max_deposit_value: null,
                daily_withdraw_amount: null,
                daily_deposit_amount: null,
                daily_number_deposit: null,
                daily_number_withdraw: null,
                daily_deposit_amount_per_cpf: null,
                daily_withdraw_amount_per_cpf: null,
                daily_number_withdraw_per_cpf: null,
                daily_number_deposit_per_cpf: null,
                withdraw_enabled: { value: true, text: 'Ativado' },
                deposit_enabled: { value: true, text: 'Ativado' },
                manual_withdraw: { value: true, text: 'Ativado' },
            },
            withdraw_enabled: null,
            deposit_enabled: null,
            manual_withdraw: { value: false, text: 'Desativado' },
            enabledOptions: [
                { value: true, text: 'Ativado' },
                { value: false, text: 'Desativado' }
            ],
            loading: {
                availableVendors: false
            },
            tryingToCreateConfiguration: false,
            tryingToUpdateConfiguration: false
        })

        if (props.configurationToUpate) {

            state.loading.availableVendors = true

            Object.assign(state.configuration, props.configurationToUpate)

            state.selectedVendors = state.configuration.vendors
            state.withdraw_enabled = { value: state.configuration.withdraw_enabled, text: changeBooleanToText(state.configuration.withdraw_enabled) }
            state.deposit_enabled = { value: state.configuration.deposit_enabled, text: changeBooleanToText(state.configuration.deposit_enabled) }
            state.manual_withdraw = { value: state.configuration.manual_withdraw, text: changeBooleanToText(state.configuration.manual_withdraw) }

        }


        if (props.isCloning) {
            state.selectedVendors = []
            state.configuration.vendors = []
        }

        const { success, error } = useNotificationToast()
        const store = useStore()
        const route = useRoute()
        const router = useRouter()

        loadAvailableVendors()

        function loadAvailableVendors() {
            state.loading.availableVendors = true

            const token = store.getters.getToken
            const clientId = route.params.id

            services.configurations.getAvailableVendors({ clientId, token })
                .then((data) => {
                    const availableVendors = data.data
                    state.availableVendors = state.selectedVendors.concat(availableVendors)
                })
                .catch(() => {
                    error("Não foi possível carregar os fornecedores.")
                })
                .finally(() => { state.loading.availableVendors = false })
        }

        function changeBooleanToText(value) {
            return value ? 'Ativado' : 'Desativado'
        }

        const hasEmptyValues = computed(() => {

            let hasNullValue = false

            for (const key in state.configuration) {

                if (key !== 'priority' && state.configuration[key] === null) {
                    hasNullValue = true
                    break
                }

            }

            return hasNullValue
        })

        const buttonLabel = computed(() => {
            const action = props.isUpdanting ? 'Atualizar' : 'Criar '
            return action + ' Configuração'
        })

        function checkInvalidValuesAndCallApi() {
            if (hasEmptyValues.value) {
                return error('Verifique todos os campos!')
            }

            if (checkIfHasInvalidValues()) {
                return error('Valores inválidos!')
            }

            props.isUpdanting ? tryToUpdateConfiguration() : tryToCreateConfiguration()
        }

        function checkIfHasInvalidValues() {

            let hasInvalidValues = false

            Object.keys(state.configuration).forEach(item => {


                if (item != 'manual_withdraw' && item != 'priority') {

                    if (item != 'id' && item != 'client_id' && item != 'created_at' && item != 'updated_at' && item != 'vendor' && typeof state.configuration[item] === 'number' && state.configuration[item] < 0.01) {
                        hasInvalidValues = true

                    }
                }

            })

            return hasInvalidValues

        }

        function getIdsFromSelectedVendors() {
            const selectedVendorsId = []

            state.selectedVendors.forEach(vendor => {
                selectedVendorsId.push(vendor.id)
            })

            return selectedVendorsId
        }

        async function tryToUpdateConfiguration() {

            state.tryingToUpdateConfiguration = true

            const token = store.getters.getToken

            state.configuration.withdraw_enabled = state.withdraw_enabled
            state.configuration.deposit_enabled = state.deposit_enabled
            state.configuration.manual_withdraw = state.manual_withdraw

            const vendorsId = getIdsFromSelectedVendors()

            await services.configurations.update({
                vendorsId,
                configuration: state.configuration,
                token
            }).then(() => {

                success('Configuração atualizada com sucesso!')

                context.emit('closeUpdateModal')

            }).catch((e) => {

                const message = e.response.data.message
                const toastMessage = message.charAt(0).toUpperCase() + message.slice(1)
                error(toastMessage)

            }).finally(() => { state.tryingToUpdateConfiguration = false })

        }

        async function tryToCreateConfiguration() {

            state.tryingToCreateConfiguration = true

            const token = store.getters.getToken

            state.configuration.withdraw_enabled = state.withdraw_enabled
            state.configuration.deposit_enabled = state.deposit_enabled
            state.configuration.manual_withdraw = state.manual_withdraw

            const vendorsId = getIdsFromSelectedVendors()

            await services.configurations.create({
                clientId: props.client.id,
                vendorsId,
                configuration: state.configuration,
                token
            }).then(() => {

                state.tryingToCreateConfiguration = false
                state.selectedVendors = []
                state.configuration = {
                    min_transaction_value: null,
                    max_transaction_value: null,
                    daily_withdraw_amount: null,
                    min_withdraw_value: null,
                    max_withdraw_value: null,
                    daily_deposit_amount: null,
                    min_deposit_value: null,
                    max_deposit_value: null,
                }
                state.withdraw_enabled = null
                state.deposit_enabled = null
                state.manual_withdraw = null

                if (props.isCloning) {

                    success('Configuração criada com sucesso!')

                    context.emit('closeUpdateModal')

                } else {

                    router.push({ name: 'client-configurations', params: { id: props.client.id } }).then(() => {
                        context.emit('realoadConfigurations', { successMessage: 'Configuração criada com sucesso!', tabMenuIndex: 0 })
                    })

                }

            }).catch((error) => {
                error('Erro: Não foi possível regisrar o Fornecedor')
            }).finally(() => { state.tryingToCreateConfiguration = false })

        }

        return {
            state,
            changeBooleanToText,
            checkInvalidValuesAndCallApi,
            buttonLabel
        }

    }
}
</script>